.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
}

.footer-item {
    display: block;
}

.copyright {
    align-self: center;
}
