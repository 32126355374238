.catWindow {
    text-align: center;
}

.weatherInfo {
    font-size: 12px;
    font-family: "'Raleway', sans-serif";
}

.windowFrame {
    width: 800px;
    height: 800px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.windowView {
    display: inline-block;
}

.cloud {
    will-change: transform;
    transform: translateX(-100%);
}

.c1 {
    animation: move 35s infinite linear;
    animation-delay: -12s;
}

.c2 {
    animation: move 30s infinite linear;
    animation-delay: -25s;
}
.c3 {
    animation: move 33s infinite linear;
    animation-delay: -21s;
}
.c4 {
    animation: move 37s infinite linear;
    animation-delay: -17s;
}
.c5 {
    animation: move 39s infinite linear;
    animation-delay: -24s;
}
.c6 {
    animation: move 45s infinite linear;
    animation-delay: -20s;
}
.c7 {
    animation: move 60s infinite linear;
    animation-delay: -29s;
}
.snow {
    will-change: transform;
    transform: translateY(-100%);
}
.s-top-layer {
    animation: fall 22.5s infinite linear;
    animation-delay: -10s;
}

.s-bottom-layer {
    animation: fall 45s infinite linear;
    animation-delay: -20s;
}
.s-middle-layer {
    animation: fall 33s infinite linear;
}

.rain {
    will-change: transform;
    transform: translateY(-100%);
}

.r-top-layer {
    animation: fall 1.7s infinite linear;
}

.r-bottom-layer {
    animation: fall 1.2s infinite linear;
}

.r-middle-layer {
    animation: fall 1.5s infinite linear;
    animation-delay: -1s;
}

.second {
    animation: fall 1s infinite linear;
    animation-delay: -0.5s;
}

@keyframes fall {
    from {
        transform: translateY(-370px);
    }
    to {
        transform: translateY(370px);
    }
}

@keyframes move {
    from {
        transform: translateX(-415px);
    }
    to {
        transform: translateX(415px);
    }
}
