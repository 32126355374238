.header {
    z-index: 9999;
    width: 100%;
    top: 0px;
    height: 50px;
}

.name {
    float: left;    
}

.pages {
    float: right;
}

.item {
    margin: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: inherit;
}